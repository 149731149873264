// this goes here because we want to execute it as soon as possible.
import './performance-mark-polyfill.js';
import 'regenerator-runtime/runtime';
import './index.css';
import domReady from './isDomReady';



domReady(async ()=>{
  performance?.mark('dom-ready');
  performance.measure('Δ dom-ready', 'vanilla-boot', 'dom-ready');

});

(async () => {
  performance?.mark('js-start');

  // (await import('./modules/weinre.js')).default();
  // performance?.mark('after-weinre');

  // performance.measure('Δ weinre', 'dom-ready', 'after-weinre');

  await (await import('./modules/polyfills.js')).default();
  performance?.mark('after-polyfills');

  performance.measure('Δ polyfills', 'js-start', 'after-polyfills');

  await (await import('./modules/xdkConfig.js')).default();
  performance?.mark('after-xdk-config');

  performance.measure('Δ xdk config',  'after-polyfills', 'after-xdk-config');

  await (await import('./modules/xdkInit.js')).default();
  performance?.mark('after-init');

  performance.measure('Δ xdk init', 'after-xdk-config', 'after-init');

  await (await import('./modules/logWelcome.js')).default();
  performance?.mark('after-log-welcome');

  performance.measure('Δ xdk log init', 'after-init', 'after-log-welcome');

  (await import('./modules/exposeGlobals.js')).default();

  await (await import('./modules/playback.js')).default();
  performance?.mark('after-playback');

  performance.measure('Δ playback', 'after-log-welcome', 'after-playback');

  await (await import('./modules/printPerformanceMarks.js')).default();
  await (await import('./modules/networkHandling.js')).default();
  await (await import('./modules/keyHandling.js')).default();
  await (await import('./modules/tts.js')).default();

})();
