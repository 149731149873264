if (!window.performance) {
  const startTime = new Date() - 0;

  const performance = {};
  const marks = [];
  const measures = [];

  const isDefined = (param) => typeof param !== 'undefined' && param !== null;
  const isNumber = (param) => !isNaN(param);
  const isObject = (obj) => typeof obj === 'object' && obj !== null && !Array.isArray(obj);
  const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

  const createResponse = (name) => { return {detail: null, entryType: "measure", name, startTime: 0, duration: null}};

  const getMark = (label) => {
    if (label === undefined) {
      return {startTime: 0};
    }

    if (isNumber(label)){
      return {startMark:label}
    }


    let mark = marks.find(({label:markLabel})=>markLabel===label);

      if (!mark) {
        throw `Failed to execute 'measure' on 'Performance': The mark '${label.toString()}' does not exist.`;
      }

      return mark;

  };

  const oneParam = (name, first) => {
    const response = createResponse(name);

    if (isEmptyObject(first) ) {
      return {...response, duration: new Date() - startTime}
    }

    if (isObject(first)) {
      const {start, end, duration, detail=null} = first;

      if (isDefined(start) &&isDefined(end)&&isDefined(duration)) {
        throw `Failed to execute 'measure' on 'Performance': If a non-empty PerformanceMeasureOptions object was passed, it must not have all of its 'start', 'duration', and 'end' properties defined;`
      }

      if (isDefined(start)&& isDefined(end)){
        let startMark = getMark(start);
        let endMark = getMark(end);

        return {...response, detail, startTime: startMark.startTime, duration: endMark.startTime - startMark.startTime };
      }

      if (isDefined(start) && isDefined(duration)  && isNumber(duration)) {
        let startMark = getMark(start);

        return {...response, detail, startTime: startMark.startTime, duration };
      }

      if (isDefined(end) && isDefined(duration) && isNumber(duration)) {
        let endMark = getMark(end);

        return {...response, detail, startTime: endMark.startTime - duration, duration };
      }

      if (isDefined(start)) {
        let startMark = getMark(start);

        return {...response, detail, startTime: 0, duration: startMark.startTime - startTime };
      }

      if (isDefined(end)) {
        let endMark = getMark(end);

        return {...response, detail, startTime: 0, duration: endMark.startTime };
      }
      if (isDefined(duration)) {
        throw "Failed to execute 'measure' on 'Performance': If a non-empty PerformanceMeasureOptions object was passed, at least one of its 'start' or 'end' properties must be present.";
      }

    }

    const mark = getMark(first);

    if (mark) {
      return {...response, startTime: mark.startTime, duration: new Date() - startTime - mark.startTime }
    }
  };



  const twoParams = (name, start, end) => {
    const response = createResponse(name);

    const startMark = getMark(start);
    const endMark = getMark(end);

    return {...response, startTime:startMark.startTime, duration: endMark.startTime - startMark.startTime};
  }

  performance.measure = (name, ...params) => {
    let measure;

    if (!name){
      throw "Failed to execute 'measure' on 'Performance': 1 argument required, but only 0 present.";
    }

    switch(params.length) {
      case 0:
        measure = {...createResponse(), duration: new Date() - startTime};
        break;
      case 1:
        measure = oneParam(name, ...params);
        break;
      case 2:
        measure = twoParams(name, ...params);
        break
    }
    measures.push(measure);

    return measure;
  }

  performance.mark = (label) => {
    const mark = {label, startTime:new Date() - startTime}

    marks.push(mark);

    return mark;
  };

  performance.getEntriesByType = (type) => {
    switch (type) {
      case 'mark':
        return marks;
      case 'measure':
        return measures;

    }
  }
  window.performance = performance;
}
