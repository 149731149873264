const runningOnBrowser = typeof window !== 'undefined' && document;
const callbacks = [];
let isReady = !runningOnBrowser; // On Node.js, we just assume things are ready.

// Sets the page as loaded.
const pageLoaded = () => {
  if (!isReady) {
    isReady = true;

    callbacks.forEach(callback => callback(document));
  }
};

if (runningOnBrowser) {
  if (document.addEventListener && window.addEventListener) {
    document.addEventListener('DOMContentLoaded', pageLoaded, false);
    window.addEventListener('load', pageLoaded, false);
    window.addEventListener('show', pageLoaded, false);
    window.onShow = pageLoaded;
  } else if (window.attachEvent) {
    window.attachEvent('onload', pageLoaded);
  }

  if (document.readyState === 'complete') {
    pageLoaded();
  }
}

/**
 * Registers a callback for DOM ready. If DOM is already ready, the
 * callback is called immediately. Globally accessible.
 * @param {Function} callback the callback function to register
 * @method
 * @memberof domReady
 */
const domReady = callback => {
  const context = runningOnBrowser ? document : undefined;

  if (isReady) {
    callback(context);
  } else {
    callbacks.push(callback);
  }

  return domReady;
};

export default domReady;
